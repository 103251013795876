/* eslint-disable jsx-a11y/alt-text */
import { TapButton, AppCSS, Spacer, TxtInput } from "../../../components";
import { Lbl } from "../../../components/labels";
import { ColDiv, RowDiv } from "../../../components/misc/misc.styled";
import {
  LeadIcon,
  SearchIcon,
  PreviousIcon,
  RowLeadIconDiv,
  SearchInputBox,
} from "./leads.styled";

import DisplayLeadDetailsCard from "./DisplayLeadDetailCard";

import * as React from "react";
import { useDispatch } from "react-redux";
import { LeadModel } from "../../../redux/types";
import { Container } from "../../../globalstyled";
import moment from "moment";

import { Box, Drawer } from "@mui/material";
import searchIcon from "../../../images/searchIcon.png";
import previousIcon from "../../../images/previousIcon.png";
import nextIcon from "../../../images/nextIcon.png";
import cancelIcon from "../../../images/CancelIcon.png";

import LeadsTableHeaderCard from "../../../components/table/CustomTableHeaderCard";
import LeadsTableBodyCard from "../../../components/table/CustomTableBodyCard";
import { LEADSTABLEHEADERLIST } from "../../../contants/dashboardHeader";

import { LEAD_DRAWER_DETAILS } from "../../../contants/projectDetails";
import { LeadDrawerDetails } from "../../../types/type";

interface UserTableProps {
  leads: LeadModel[];
}

export const LeadsTable: React.FC<UserTableProps> = ({ leads }) => {
  const dispatch = useDispatch();

  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(8);
  const [keyword, setKeyword] = React.useState("");
  const [isLeads, setIsLeads] = React.useState([]);

  const inputRef = React.useRef<HTMLInputElement>(null);
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [selectedLead, setSelectedLead] = React.useState(null);

  const handleSearchIconClick = () => {
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  };

  const filterLeads = () => {
    if (keyword.length > 0 && Array.isArray(leads)) {
      return leads.filter((item) => {
        return (
          item.street_address.toLowerCase().includes(keyword.toLowerCase()) &&
          item.utm !== "waitlist"
        );
      });
    }

    return leads.filter((item) => item.utm !== "waitlist");
  };

  const goToPreviousPage = () => {
    setPage((prevPage) => Math.max(prevPage - 1, 0));
  };

  const goToNextPage = () => {
    setPage((prevPage) =>
      Math.min(prevPage + 1, Math.ceil(filterLeads().length / rowsPerPage) - 1)
    );
  };

  const handleDrawerOpen = (lead: any) => {
    return () => {
      setSelectedLead(lead);
      setOpenDrawer(true);
    };
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  const showLeadsDetails = () => {
    return (
      <Drawer
        anchor="right"
        open={openDrawer}
        onClose={handleDrawerClose}
        sx={{
          "& .MuiDrawer-paper": {
            height: "100%",
            borderTopLeftRadius: "20px",
            borderBottomLeftRadius: "20px",
            overflowX: "hidden",
          },
        }}
      >
        <div
          onKeyDown={handleDrawerClose}
          style={{
            backgroundColor: AppCSS.WHITE,
            color: "black",
            height: "100%",
            display: "flex",
            width: "440px",
            flexDirection: "column",
            justifyContent: "start",
            alignItems: "start",
            padding: "20px",
            gap: "30px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "start",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <LeadIcon
              src={cancelIcon}
              onClick={handleDrawerClose}
              style={{
                width: "12px",
                height: "12px",
                marginRight: "10px",
                cursor: "pointer",
              }}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "start",
                width: "100px",
                marginRight: "10px",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Lbl title={`Lead ${selectedLead?.id}`} size={15} width={80} />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              alignItems: "center",
              width: "100%",
              paddingX: "20px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                width: "calc(100% - 20px)",
                marginRight: "20px",
                marginBottom: "20px",
              }}
            >
              {LEAD_DRAWER_DETAILS.map(
                (detail: LeadDrawerDetails, index: number) => (
                  <DisplayLeadDetailsCard
                    key={`lead drawer details  ${index}`}
                    label={detail.label}
                    value={
                      detail.isDate
                        ? new Date(
                            selectedLead?.[detail.key]
                          ).toLocaleDateString()
                        : selectedLead?.[detail.key]
                    }
                  />
                )
              )}
            </Box>
          </Box>
        </div>
      </Drawer>
    );
  };

  const LeadsTable = () => {
    return (
      <Container>
        <Spacer size={2} direction="col" />

        <ColDiv
          style={{
            width: "95%",
            marginBottom: "1%",
            gap: "10px",
          }}
        >
          <LeadsTableHeaderCard headers={LEADSTABLEHEADERLIST} />
          {filterLeads()
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, rowIndex) => (
              <LeadsTableBodyCard
                key={`leadDetails ${rowIndex}`}
                onViewLeadsDetails={handleDrawerOpen(row)}
                isViewDetails={true}
                bodyContent={[
                  {
                    text: `${row.street_address}, ${row.city}`,
                    width: "30%",
                    fontSize: "15px",
                  },
                  {
                    text:
                      row.utm === "referral" || row.utm === "sales"
                        ? row.utm.toLocaleUpperCase() + " by " + row.ref_id
                        : row.utm.toLocaleUpperCase(),
                    width: "10%",
                  },
                  { text: row.type_of_damaged, width: "10%" },
                  {
                    text: new Date(row.date_of_damaged).toLocaleDateString(),

                    width: "10%",
                  },
                  {
                    text: `${moment(row.created_at).fromNow(true)} ago`,
                    width: "10%",
                  },
                ]}
              />
            ))}
        </ColDiv>
        <RowDiv
          style={{
            justifyContent: "end",
            marginTop: "20px",
            marginBottom: "5%",
            alignItems: "center",
            marginRight: "10%",
          }}
        >
          <PreviousIcon src={previousIcon} onClick={() => goToPreviousPage()} />
          <span style={{ margin: "0 15px" }}>
            Page {page + 1} of {Math.ceil(filterLeads().length / rowsPerPage)}
          </span>
          <PreviousIcon src={nextIcon} onClick={() => goToNextPage()} />
        </RowDiv>
      </Container>
    );
  };

  const userContainer = () => {
    return (
      <Container>
        <Spacer size={2} direction="col" />
        <ColDiv
          style={{
            display: "row",
            justifyContent: "space-around",
            marginLeft: "10px",
            marginRight: "auto",
          }}
        >
          <RowLeadIconDiv
            style={{
              gap: "10px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
                alignItems: "center",
                gap: "10px",
                marginLeft: "3%",
              }}
            >
              <LeadIcon
                src={
                  "https://imagedelivery.net/ljlAKBP_91m1yO7rrhjKgQ/63f25a4a-87a9-43ba-a8f3-efd70e63ee00/chatImage"
                }
              />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  width: "100px",
                  marginRight: "10px",
                }}
              >
                <Lbl title="Leads" size={22} width={80} />
              </Box>
            </Box>

            <SearchInputBox
              sx={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                borderRadius: "10px",
                backgroundColor: "white",

                boxShadow: "5px 5px 5px rgba(0, 0, 0, 0.1)",
              }}
            >
              <SearchIcon
                src={searchIcon}
                style={{
                  margin: "10px",
                  background: "none",
                }}
                onClick={handleSearchIconClick}
              />
              <TxtInput
                placeholder={`Search Address`}
                onChange={setKeyword}
                inputRef={inputRef}
                background="white"
              />
            </SearchInputBox>
          </RowLeadIconDiv>
          <RowDiv
            style={{
              justifyContent: "center",
              alignItems: "center",
              background: "none",
            }}
          >
            <Spacer direction="col" />
            {Array.isArray(leads) && LeadsTable()}
          </RowDiv>
        </ColDiv>
        {showLeadsDetails()}
      </Container>
    );
  };

  return userContainer();
};
