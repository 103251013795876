import * as React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Tabs } from "@mui/material";
import ProductSystems from "./ProductSystems";
import MiscFields from "./MiscFields";
import MaterialCategories from "./MaterialCategories";
import MaterialMeasurements from "./MaterialMeasurements";
import MaterialItems from "./MaterialItems";
import LaborMeasurements from "./LaborMeasurements";
import Contractors from "./Contractors";
import LaborItems from "./LaborItems";
import ContractorReportTypes from "./ContractorReportTypes";
import ContractorChangeOrders from "./ContractorChangeOrders";

export default function MaterialCreation() {
  const [value, setValue] = React.useState("1");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <div>
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              onChange={handleChange}
              value={value}
              aria-label="lab API tabs example"
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab label="Product Systems" value="1" />
              <Tab label="Misc Fields" value="2" />
              <Tab label="Material Categories" value="3" />

              <Tab label="Material Measurements" value="4" />
              <Tab label="Materials" value="5" />
              <Tab label="Labor Measurements" value="6" />
              <Tab label="Contractors" value="7" />
              <Tab label="Labor" value="8" />
              <Tab label="Contractor Reports" value="9" />
              <Tab label="Contractor Change Orders" value="10" />
            </Tabs>
          </Box>
          <TabPanel value="1">
            <ProductSystems />
          </TabPanel>
          <TabPanel value="2">
            <MiscFields />
          </TabPanel>
          <TabPanel value="3">
            <MaterialCategories />
          </TabPanel>
          <TabPanel value="4">
            <MaterialMeasurements />
          </TabPanel>
          <TabPanel value="5">
            <MaterialItems />
          </TabPanel>
          <TabPanel value="6">
            <LaborMeasurements />
          </TabPanel>
          <TabPanel value="7">
            <Contractors />
          </TabPanel>
          <TabPanel value="8">
            <LaborItems />
          </TabPanel>
          <TabPanel value="9">
            <ContractorReportTypes />
          </TabPanel>
          <TabPanel value="10">
            <ContractorChangeOrders />
          </TabPanel>
        </TabContext>
      </Box>
    </div>
  );
}
