import { HeaderList } from "../types/interface";

export const LEADSTABLEHEADERLIST: HeaderList[] = [
  { text: "Address", width: "30%" },
  { text: "Type", width: "10%" },
  { text: "Damage Type", width: "10%" },
  { text: "Damage Date", width: "10%" },
  { text: "Created At", width: "10%" },
];

export const WAITLISTSTABLEHEADERLIST: HeaderList[] = [
  { text: "First Name", width: "13%" },
  { text: "Last Name", width: "13%" },
  { text: "Phone Number", width: "16%" },
  { text: "Email", width: "14%" },
  { text: "Street Address", width: "22%" },
  { text: "City", width: "10%" },
  { text: "Created At", width: "12%" },
];

export const PROJECTSTABLEHEADERLIST: HeaderList[] = [
  { text: "Project ID", width: "10%" },
  { text: "Customer Name", width: "20%" },
  { text: "Phone Number", width: "20%" },
  { text: "Address", width: "35%" },
  { text: "", width: "8%", justifyContent: "center" },
];
