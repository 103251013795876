import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  AdminUserModel,
  LeadModel,
  Project,
  ProjectDetails,
  UserModel,
  WaitlistModel,
  MenuItemModel,
} from "../types";
import { InsuranceCompany } from "../types/InsuranceCompany";
import { Material } from "../types/Material";
import { Estimate, EstimateAddOns } from "../types/Estimate";
import {
  ContractorChangeOrders,
  ContractorReportTypes,
  Contractors,
  LaborItems,
  LaborMeasurements,
  MaterialCategories,
  MaterialItems,
  MaterialMeasurements,
  MiscFields,
  ProductSystem,
} from "../types/creation";

export interface UserState {
  userProfile: UserModel;
  adminUsers: AdminUserModel[];
  leads: LeadModel[];
  waitlist: WaitlistModel[];
  projects: Project[];
  insuranceCompanies: InsuranceCompany[];
  projectDetails: ProjectDetails;
  materials: Material[];
  estimate: Estimate;
  estimateAddOns: EstimateAddOns[];

  productSystems: ProductSystem[];
  miscFields: MiscFields[];
  materialCategories: MaterialCategories[];
  materialMeasurements: MaterialMeasurements[];
  materialItems: MaterialItems[];
  laborMeasurements: LaborMeasurements[];
  contractors: Contractors[];
  laborItems: LaborItems[];
  contractorReportTypes: ContractorReportTypes[];
  contractorChangeOrders: ContractorChangeOrders[];

  drawerMenuItem: MenuItemModel;
}

const initialState: UserState = {
  userProfile: {} as UserModel,
  adminUsers: {} as AdminUserModel[],
  leads: {} as LeadModel[],
  projects: {} as Project[],
  waitlist: {} as WaitlistModel[],
  insuranceCompanies: {} as InsuranceCompany[],
  projectDetails: {} as ProjectDetails,
  materials: {} as Material[],
  estimate: {} as Estimate,
  estimateAddOns: {} as EstimateAddOns[],

  productSystems: {} as ProductSystem[],
  miscFields: {} as MiscFields[],
  materialCategories: {} as MaterialCategories[],
  materialMeasurements: {} as MaterialMeasurements[],
  materialItems: {} as MaterialItems[],
  laborMeasurements: {} as LaborMeasurements[],
  contractors: {} as Contractors[],
  laborItems: {} as LaborItems[],
  contractorReportTypes: {} as ContractorReportTypes[],
  contractorChangeOrders: {} as ContractorChangeOrders[],

  drawerMenuItem: {
    activeMenuItem: "",
    isSettingActive: false,
  } as MenuItemModel,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    userLogin(state, action: PayloadAction<UserModel>) {
      const profileData = action.payload;
      state.userProfile = profileData;
    },
    userLogout(state) {
      state.userProfile = {} as UserModel;
    },
    setLeads(state, action: PayloadAction<LeadModel[]>) {
      console.log("ACTION:", action);
      state.leads = action.payload;
    },
    setWaitlist(state, action: PayloadAction<WaitlistModel[]>) {
      state.waitlist = action.payload;
    },
    setProjects(state, action: PayloadAction<Project[]>) {
      console.log("ACTION:", action);
      state.projects = action.payload;
    },
    setInsuranceCompanies(state, action: PayloadAction<InsuranceCompany[]>) {
      state.insuranceCompanies = action.payload;
    },
    setProjectDetails(state, action: PayloadAction<ProjectDetails>) {
      state.projectDetails = action.payload;
    },
    setMaterials(state, action: PayloadAction<Material[]>) {
      state.materials = action.payload;
    },
    setEstimate(state, action: PayloadAction<Estimate>) {
      state.estimate = action.payload;
    },
    setEstimateAddOns(state, action: PayloadAction<EstimateAddOns[]>) {
      state.estimateAddOns = action.payload;
    },
    setAdminUsers(state, action: PayloadAction<AdminUserModel[]>) {
      state.adminUsers = action.payload;
    },

    setProductSystems(state, action: PayloadAction<ProductSystem[]>) {
      state.productSystems = action.payload;
    },
    setMiscFields(state, action: PayloadAction<MiscFields[]>) {
      state.miscFields = action.payload;
    },
    setMaterialCategories(state, action: PayloadAction<MaterialCategories[]>) {
      state.materialCategories = action.payload;
    },
    setMaterialMeasurements(
      state,
      action: PayloadAction<MaterialMeasurements[]>
    ) {
      state.materialMeasurements = action.payload;
    },
    setMaterialItems(state, action: PayloadAction<MaterialItems[]>) {
      state.materialItems = action.payload;
    },
    setLaborMeasurements(state, action: PayloadAction<LaborMeasurements[]>) {
      state.laborMeasurements = action.payload;
    },
    setContractors(state, action: PayloadAction<Contractors[]>) {
      state.contractors = action.payload;
    },
    setLaborItems(state, action: PayloadAction<LaborItems[]>) {
      state.laborItems = action.payload;
    },
    setContractorReportTypes(
      state,
      action: PayloadAction<ContractorReportTypes[]>
    ) {
      state.contractorReportTypes = action.payload;
    },
    setContractorChangeOrders(
      state,
      action: PayloadAction<ContractorChangeOrders[]>
    ) {
      state.contractorChangeOrders = action.payload;
    },

    setDrawerActiveMenuItem(state, action: PayloadAction<MenuItemModel>) {
      state.drawerMenuItem.activeMenuItem = action.payload.activeMenuItem;
    },
    setDrawerIsSettingActive(state, action: PayloadAction<MenuItemModel>) {
      state.drawerMenuItem.isSettingActive = action.payload.isSettingActive;
    },
  },
});
export const {
  userLogin,
  setLeads,
  setWaitlist,
  setProjects,
  setInsuranceCompanies,
  setProjectDetails,
  setMaterials,
  setEstimate,
  setEstimateAddOns,
  userLogout,
  setAdminUsers,

  setProductSystems,
  setMiscFields,
  setMaterialCategories,
  setMaterialMeasurements,
  setMaterialItems,
  setLaborItems,
  setLaborMeasurements,
  setContractorChangeOrders,
  setContractorReportTypes,
  setContractors,

  setDrawerActiveMenuItem,
  setDrawerIsSettingActive,
} = userSlice.actions;
export default userSlice.reducer;
