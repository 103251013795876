/* eslint-disable jsx-a11y/alt-text */
import {
  Toolbar,
  Typography,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  Box,
} from "@mui/material";
import {
  LeftIcon,
  LogoSmall,
  NavAppBar,
  NavLink,
  NavLinkNormal,
  RightNav,
  ColDiv,
  NavBarIcon,
  HomeIcon,
  NavBarArrowIcon,
  CenterNav,
} from "./navbar.styled";
import NotificationIcon from "../../images/notification.svg";
import AvatarIcon from "../../images/avatar.png";
import React, { useEffect, useState } from "react";
import { AppCSS, Spacer } from "..";
import { useNavigate } from "react-router-dom";

import { useAppSelector } from "../../redux/hooks";
import { MENU_ITEMS } from "../../contants/drawerLink";
import { NavItemProps } from "../../types/interface";
import LogoIcon from "../../images/companylogo.png";
import BackArrow from "../../images/backIcon.png";
import BackArrowG from "../../images/backIcon_G.png";
import UpArrowIcon from "../../images/upArrowIcon.png";
import DownArrowIcon from "../../images/downArrowIcon.png";

export const NavItem: React.FC<NavItemProps> = ({
  title,
  linkTo,
  selected,
}) => {
  return (
    <NavLink
      style={{
        border: selected ? "1px solid #3E6990" : "none",
      }}
      to={linkTo ? linkTo : "#"}
    >
      {title && title}
    </NavLink>
  );
};

export const NavItemNotification: React.FC<NavItemProps> = ({
  linkTo,
  title,
}) => {
  return (
    <NavLinkNormal
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
      }}
      to={linkTo ? linkTo : "#"}
    >
      <img src={NotificationIcon} width={28} height={28} />
      {title && (
        <label
          style={{
            color: AppCSS.GLAS_BLUE,
            height: "5px",
            width: "5px",
            top: "10px",
            position: "absolute",
            fontSize: "12px",
          }}
        >
          ●
        </label>
      )}
    </NavLinkNormal>
  );
};

export const NavItemProfile: React.FC<NavItemProps> = ({
  linkTo,
  title,
  onClick,
  id,
}) => {
  return (
    <Button
      id={id}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      variant="text"
      onClick={() => (onClick ? onClick() : {})}
    >
      <img src={AvatarIcon} width={38} height={38} />
      {title && (
        <label
          style={{
            color: "#2E2C34",
            fontSize: "13px",
            marginLeft: "10px",
          }}
        >
          {title}
        </label>
      )}
    </Button>
  );
};

export const ProfileMenu: React.FC = () => {
  const navigate = useNavigate();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [showSubMenu, setShowSubMenu] = useState(false);
  const [isRemoveHover, setIsRemoveHover] = useState(false);
  const [activeMenuItem, setActiveMenuItem] = useState<string | null>("/leads");
  const [isSettingActive, setIsSettingActive] = useState(false);
  const [isBackIconActive, setIsBackIconActive] = useState(false);

  const [hoveredItem, setHoveredItem] = useState(null);

  useEffect(() => {
    const storedActiveMenuItem = localStorage.getItem("activeMenuItem");
    if (storedActiveMenuItem) {
      setActiveMenuItem(storedActiveMenuItem);
    }
    const isSettingActive = localStorage.getItem("isSettingActive");
    if (isSettingActive === "true") {
      setIsSettingActive(true);
    }
  }, []);

  const handleMouseEnter = (index) => {
    setHoveredItem(index);
  };

  const handleMouseLeave = () => {
    setHoveredItem(null);
  };

  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };

  const handleDrawerClose = () => {
    setShowSubMenu(false);
    setOpenDrawer(false);
  };

  const handleSubMenuToggle = () => {
    setIsRemoveHover(!isRemoveHover);
    setShowSubMenu(!showSubMenu);
  };

  const handleMenuItemClick = (route: string) => {
    setActiveMenuItem(route);
    localStorage.setItem("activeMenuItem", route);
    if (route !== "/settings") {
      setIsSettingActive(false);
      localStorage.setItem("isSettingActive", "false");
    }

    navigate(route);
  };

  const handleSubMenuItemClick = (route: string) => {
    setActiveMenuItem(route);
    localStorage.setItem("activeMenuItem", route);
    localStorage.setItem("isSettingActive", "true");
    navigate(route);
  };

  const allowedMenu = () => {
    return (
      <Drawer anchor="left" open={openDrawer} onClose={handleDrawerClose}>
        <div
          onKeyDown={handleDrawerClose}
          style={{
            backgroundColor: "black",
            color: "white",
            height: "100%",
            display: "flex",
            width: "313px",
            flexDirection: "column",
            justifyContent: "start",
            alignItems: "start",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              width: "273px",
              height: "100px",
              marginX: "20px",
            }}
          >
            <HomeIcon
              onClick={() => {
                handleDrawerClose();
                navigate("/dashboard");
              }}
            >
              <LogoSmall src={LogoIcon} />
            </HomeIcon>
            <LeftIcon
              onClick={handleDrawerClose}
              onMouseEnter={() => {
                setIsBackIconActive(true);
              }}
              onMouseLeave={() => {
                setIsBackIconActive(false);
              }}
            >
              <NavBarIcon
                src={
                  isBackIconActive
                    ? "https://imagedelivery.net/ljlAKBP_91m1yO7rrhjKgQ/16db2786-b1e9-427f-d84f-f2d669d50f00/chatImage"
                    : "https://imagedelivery.net/ljlAKBP_91m1yO7rrhjKgQ/977dc576-8b65-4ad9-23fc-b9b9a860ac00/chatImage"
                }
              />
            </LeftIcon>
          </Box>
          <Spacer size={1} direction="col" />

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "start",
              alignItems: "center",
            }}
          >
            <List>
              {MENU_ITEMS.map((item, index) => (
                <ListItemButton
                  key={item.id}
                  sx={{
                    color:
                      activeMenuItem === item.route ? AppCSS.GREEN : "inherit",
                    width: "313px",
                    "&:hover": {
                      color: AppCSS.GREEN,
                    },
                  }}
                  onMouseEnter={() => {
                    handleMouseEnter(item.id);
                  }}
                  onMouseLeave={handleMouseLeave}
                >
                  <ListItem
                    onClick={() => {
                      if (item.subMenu) {
                        handleSubMenuToggle();
                      } else {
                        handleDrawerClose();
                        handleMenuItemClick(item.route);
                      }
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          width: "260px",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                          }}
                        >
                          <NavBarIcon
                            src={
                              activeMenuItem === item.route
                                ? item.icon_G
                                : hoveredItem === item.id
                                ? item.icon_G
                                : item.icon
                            }
                          />
                          <ListItemText
                            primary={item.text}
                            sx={{ marginLeft: "10px" }}
                          />
                        </Box>

                        {item.text === "Settings" ? (
                          <Box>
                            {showSubMenu ? (
                              <NavBarArrowIcon src={UpArrowIcon} />
                            ) : (
                              <NavBarArrowIcon src={DownArrowIcon} />
                            )}
                          </Box>
                        ) : null}
                      </Box>

                      {item.subMenu && showSubMenu
                        ? item.subMenu.map((subItem, subIndex) => (
                            <Box
                              sx={{
                                marginLeft: "40px",
                                color:
                                  activeMenuItem === subItem.route
                                    ? AppCSS.GREEN
                                    : AppCSS.WHITE,
                                "&:hover": {
                                  color: AppCSS.GREEN,
                                },
                              }}
                            >
                              <ListItem
                                key={subIndex}
                                onClick={() => {
                                  handleDrawerClose();
                                  setIsSettingActive(true);
                                  handleSubMenuItemClick(subItem.route);
                                }}
                              >
                                <ListItemText
                                  primary={subItem.text}
                                  sx={{ marginLeft: "10px" }}
                                />
                              </ListItem>
                            </Box>
                          ))
                        : null}
                    </Box>
                  </ListItem>
                </ListItemButton>
              ))}
            </List>
          </Box>
        </div>
      </Drawer>
    );
  };

  return (
    <div>
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textTransform: "none",
        }}
      >
        <ColDiv
          style={{
            alignItems: "center",
          }}
        >
          <Box>
            <LeftIcon
              size="large"
              color="inherit"
              aria-label="menu"
              onClick={handleDrawerOpen}
            >
              <LogoSmall src={LogoIcon} />
            </LeftIcon>
            <Spacer size={1} direction="col" />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "start",
              alignItems: "center",
              color: "white",
            }}
          >
            <List>
              {MENU_ITEMS.map((item, index) => (
                <ListItemButton
                  key={item.id}
                  style={{
                    paddingTop: "15px",
                    paddingLeft: "0px",
                    paddingRight: "0px",
                    paddingBottom: "0px",
                  }}
                >
                  <ListItem
                    onClick={() => {
                      if (item.subMenu) {
                        setOpenDrawer(true);
                        handleSubMenuToggle();
                      } else {
                        setIsSettingActive(false);
                        handleMenuItemClick(item.route);
                      }
                    }}
                    onMouseEnter={() => {
                      handleMouseEnter(index);
                    }}
                    onMouseLeave={handleMouseLeave}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                          }}
                        >
                          <NavBarIcon
                            src={
                              isSettingActive &&
                              item.route ===
                                ("/settings" || "/materials" || "/insurance")
                                ? item.icon_G
                                : activeMenuItem === item.route &&
                                  !isSettingActive
                                ? item.icon_G
                                : hoveredItem === index
                                ? item.icon_G
                                : item.icon
                            }
                          />
                        </Box>
                      </Box>
                    </Box>
                  </ListItem>
                </ListItemButton>
              ))}
            </List>
          </Box>
        </ColDiv>
      </Box>
      {allowedMenu()}
    </div>
  );
};

export const NavBar = () => {
  const navigate = useNavigate();
  const profile = useAppSelector((state) => state.userReducer).userProfile;

  const dashboardMenu = () => {
    if (profile.token) {
      return (
        <RightNav style={{}}>
          <ProfileMenu />
        </RightNav>
      );
    }
  };

  const availableOptions = () => {
    if (profile.token) {
      return dashboardMenu();
    } else {
      return null;
    }
  };

  return (
    <NavAppBar
      sx={{
        display: profile.token ? "flex" : "none",
        width: "inherit",
        height: "inherit",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box>{availableOptions()}</Box>
    </NavAppBar>
  );
};
